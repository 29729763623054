import React, { useEffect, useState } from 'react'
import { format, addDays, subDays, getWeek, addWeeks, subWeeks, lastDayOfWeek } from 'date-fns'
import { Button, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core'
import moment from 'moment'
import { useContext } from 'react'
import schedulePickupContext from '../../../Product/context/plugin/schedulePickup/schedulePickupContext'
import './Scheduler.css'
export default function Scheduler({
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    pickupData,
}) {
    const { all_timings } = useContext(schedulePickupContext)
    const [currentMonth, setCurrentMonth] = useState(new Date())
    const [startDate, setStartDate] = useState(new Date())
    const [disabledBack, setDisabledBack] = useState(true)

    const [availableSlots, setAvailableSlots] = useState([])
    const [dayOfWeek, setDayOfWeek] = useState('')
    const [timings, setTimings] = useState({
        default: [],
        scheduled: [],
    })
    const dummyResponse = [
        {
            Day: 'Monday',
            Status: 'Closed',
            Day_Times: [],
        },

        {
            Day: 'Tuesday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },

        {
            Day: 'Wednesday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },

        {
            Day: 'Thursday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },

        {
            Day: 'Friday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },

        {
            Day: 'Saturday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },

        {
            Day: 'Sunday',
            Status: 'Open',
            Day_Times: [
                {
                    start_time: '10:00 AM',
                    end_time: '06:00 PM',
                },
            ],
        },
    ]

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value)

        let formattedDate = moment(event.target.value, 'ddd DD MMM')
        setDayOfWeek(formattedDate.format('dddd'))
    }

    const handleTimeChange = (event) => {
        setSelectedTime(event.target.value)
    }

    const changeWeekHandle = (btnType) => {
        const today = moment()
        const offset = btnType === 'prev' ? -7 : 7
        const newStartDate = addDays(startDate, offset)
        setStartDate(newStartDate)

        if (moment(newStartDate).isSame(today, 'day')) {
            setDisabledBack(true)
        } else {
            setCurrentMonth(
                btnType === 'prev' ? subWeeks(currentMonth, 1) : addWeeks(currentMonth, 1),
            )
            setDisabledBack(false)
        }
    }

    const renderWeekDays = () => {
        const dateFormat = 'EEE d MMM yyyy'
        const weekDays = []
        const today = moment()

        for (let i = 0; i < 7; i++) {
            const selDay = addDays(startDate, i)
            const formattedDate = format(selDay, dateFormat)
            let day = formattedDate?.split(' ')[0]
            let date = formattedDate?.split(' ')[1]
            let month = formattedDate?.split(' ')[2]

            if (moment(selDay).isBefore(today, 'day')) {
                console.log('Older Date')
            } else {
                weekDays.push(
                    <FormControlLabel
                        className={`${formattedDate == selectedDate ? 'selected' : ''}`}
                        key={i}
                        value={formattedDate}
                        control={<Radio />}
                        label={
                            <div className="dayPreview">
                                <span>{day}</span>
                                <span>{date}</span>
                                <span>{month}</span>
                            </div>
                        }
                        disabled={
                            timings?.default[0]?.general?.find((ele) => ele.Day.includes(day))
                                ? timings?.default[0]?.general?.find((ele) => ele.Day.includes(day))
                                      ?.Status === 'Closed'
                                    ? true
                                    : false
                                : false
                        }
                    />,
                )
            }
        }

        return (
            <FormControl className="schedulerDate">
                <RadioGroup
                    aria-label="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={selectedDate}
                    onChange={handleDateChange}
                >
                    {weekDays}
                </RadioGroup>
            </FormControl>
        )
    }

    const generateTimeSlots = () => {
        let day = selectedDate?.split(' ')[0]
        console.log(day, 'dayyyyyy', selectedDate)
        const sTime =
            timings?.default[0]?.general?.find((ele) => ele.Day.includes(day))?.Day_Times[0]
                ?.start_time || '12:00 AM'
        const eTime =
            timings?.default[0]?.general?.find((ele) => ele.Day.includes(day))?.Day_Times[0]
                ?.end_time || '11:59 PM'
        console.log(sTime, eTime, timings, 'timings')
        const sDate = new Date(`2000-01-01 ${sTime}`)
        const eDate = new Date(`2000-01-01 ${eTime}`)

        const timeSlots = []

        const interval = 60 * 5 * 1000
        let currentSlot = sDate

        while (currentSlot <= eDate) {
            const slotStart = currentSlot.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            })
            timeSlots.push(slotStart)

            currentSlot = new Date(currentSlot.getTime() + interval)
        }
        return timeSlots
    }

    useEffect(() => {
        setAvailableSlots(generateTimeSlots())
    }, [selectedDate, timings])

    function addDays(date, day) {
        let new_date = new Date(date.getTime())
        new_date.setDate(new_date.getDate() + day)
        return new_date
    }

    function getDates(k, day) {
        const day_num = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ].indexOf(day.toLowerCase()) // converting day name to number

        let new_day = new Date()
        while (new_day.getDay() !== day_num) {
            new_day = addDays(new_day, 1)
        }

        return addDays(new_day, 0)
    }

    useEffect(() => {
        setTimings({
            ...timings,
            default: all_timings?.default_timings,
            scheduled: all_timings?.scheduled_timings,
        })
        console.log(
            all_timings?.default_timings,
            getDates(
                1,
                all_timings?.default_timings[0]?.general?.find((ele) => ele.Status === 'Open')?.Day,
            ),
            'all_timings?.scheduled_timings',
        )
        setSelectedDate(
            format(
                pickupData?.booking_date
                    ? new Date(pickupData?.booking_date)
                    : getDates(
                          1,
                          all_timings?.default_timings[0]?.general?.find(
                              (ele) => ele.Status === 'Open',
                          )?.Day,
                      ),
                'EEE d MMM yyyy',
            ),
        )
        setSelectedTime(
            pickupData?.booking_date
                ? format(
                      pickupData?.booking_date
                          ? new Date(pickupData?.booking_date)
                          : getDates(
                                1,
                                all_timings?.default_timings[0]?.general?.find(
                                    (ele) => ele.Status === 'Open',
                                )?.Day,
                            ),
                      'hh:mm a',
                  )
                : null,
        )
    }, [all_timings])
    return (
        <div className="scheduler">
            <div className="schedulerTop">
                Calendar
                <div className="schedulerActions">
                    <Button
                        className="schedulerMove"
                        onClick={() => changeWeekHandle('prev')}
                        disabled={disabledBack}
                    >
                        <span className="material-icons">navigate_before</span>
                    </Button>

                    <Button className="schedulerMove" onClick={() => changeWeekHandle('next')}>
                        <span className="material-icons">navigate_next</span>
                    </Button>
                </div>
            </div>

            <div className="schedulerMain">
                {renderWeekDays()}
                <div className="schedulerTime">
                    <h4 className="stTitle">{selectedDate}</h4>
                    {selectedDate ? (
                        <div className="timeUnits">
                            <FormControl>
                                <RadioGroup
                                    aria-label="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={selectedTime}
                                    onChange={handleTimeChange}
                                >
                                    {availableSlots?.map((d, i) => (
                                        <FormControlLabel
                                            key={i}
                                            value={d}
                                            control={<Radio />}
                                            label={d}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}
