import React, { useState, useEffect, useContext, useRef } from 'react'
import Container from '@material-ui/core/Container'
import _ from 'lodash'
import CheckoutCard from '../../../Component/CheckoutCard'
import MakePayment from './MakePayment'
import MakePaymentSkyflow from './MakePaymentSkyflow'
import { config } from '../../../Services/common.header'
import CartContext from '../../../Product/context/cart/cartContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { useParams, useHistory } from 'react-router-dom'
import ProductContext from '../../../Product/context/product/productContext'
import { handleRedirectInternal } from '../../../Product/common/components'
import { messageHandleBuynow } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import Nodata from '../../../Component/NoData/nodata'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import { apiCall } from '../../../Product/common/api'
import { useTranslation } from 'react-i18next'
import UserActivity from '../UserActivity'
const CheckoutComponent = (props) => {
    const cartContext = useContext(CartContext)
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const buyerContext = useContext(BuyerContext)
    const alertContext = useContext(AlertContext)
    const { t } = useTranslation()
    const [viewCartItems, setViewCartItems] = useState([])
    const [viewCartItems_buynow, setViewCartItemsBuynow] = useState([])
    const [shippingpos, setShippingpos] = useState(0)
    const [calculateTrigger, setCalculateTrigger] = useState(false)
    const [calculatorLoading, setCalculatorLoading] = useState(false)
    const [remove_id, setRemoveid] = useState(0)
    const { type } = useParams()
    const id = new URLSearchParams(window.location.search.substring(1)).getAll('id')
    const history = useHistory()
    const { setAlert } = alertContext
    const { isAuthenticated, user } = authContext
    const { addToCart, buyer_cartitems, getUserCart, removeFromCart, responseStatus } = cartContext
    const { getCheckoutAuction, invoice_details, clearResponse_invoice_details } = productContext
    const { get_ship_station_amount, shipping_rate_calculation, clear_ship_station_amount } =
        buyerContext
    const trigger = 1
    useEffect(() => {
        if (type === 'buynow') {
            console.log('trigger ', calculatorLoading)
            if (buyer_cartitems.results) {
                if (
                    global.storeConfigration?.transfer_item_location?.value == 1 &&
                    buyer_cartitems.results.length > 0
                ) {
                    var city_equal_check = global.storeConfigration?.transfer_city_location?.value
                    buyer_cartitems.results.map((val) => {
                        if (
                            val.auction_city != city_equal_check &&
                            val.auction_city?.toLowerCase()?.trim() ==
                                global.storeConfigration?.transfer_city_location_from?.value
                                    ?.toLowerCase()
                                    ?.trim() &&
                            val.localpickup == 1
                        ) {
                            val.transfer_item_enable = 1
                            if (user?.transfer_location == 1 && val.api_called == 1) {
                                console.log('++++++++++++++')
                                val.temp_shipping_fee = val.shipping_fee
                                val.total_payable_amount =
                                    val.total_payable_amount - val.temp_shipping_fee
                                val.shipping_fee = 0
                                val.transfer_fee_location = parseFloat(
                                    global.storeConfigration?.transfer_location_fee?.value,
                                )
                                val.total_payable_amount =
                                    parseFloat(val.total_payable_amount) +
                                    parseFloat(val.transfer_fee_location)
                                val.local_pick_total_payable_amount =
                                    parseFloat(val.local_pick_total_payable_amount) +
                                    parseFloat(val.transfer_fee_location)
                                val.temp_hasshipping = '0'
                            }
                        } else {
                            val.transfer_fee_location = 0
                            if (user?.transfer_location == 1 && val.api_called == 1) {
                                console.log('----++++++++++++++')
                                val.temp_shipping_fee = val.shipping_fee
                                val.total_payable_amount =
                                    val.total_payable_amount - val.temp_shipping_fee
                                val.shipping_fee = 0
                                val.temp_hasshipping = '0'
                            }
                        }
                    })
                    let temp_buyer_cartitems = [...buyer_cartitems.results]
                    setViewCartItems(temp_buyer_cartitems)
                    setViewCartItemsBuynow(temp_buyer_cartitems)
                } else {
                    setViewCartItems([...buyer_cartitems.results])
                    setViewCartItemsBuynow([...buyer_cartitems.results])
                }
            }
        }
    }, [buyer_cartitems.results])

    useEffect(() => {
        if (remove_id > 0) {
            removeFromCart({ id: remove_id })
        }
    }, [remove_id])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'removeFromCart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                }
            }
        }
    }, [responseStatus])

    useEffect(() => {
        if (type === 'auction') {
            if (isAuthenticated) {
                if (!id) {
                    return handleRedirectInternal(
                        history,
                        `dashboard/mybids/${type === 'buynow' ? 'buynow' : 'won'}`,
                    )
                }
                getCheckoutAuction({ invoice_id: id })
            }
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (type === 'auction') {
            if (invoice_details) {
                if (invoice_details.length > 0) {
                    invoice_details.map((val) => {
                        if (val.hasshipping == 1 && val.localpickup == 1) {
                            val.temp_shipping_fee = val.shipping_fee
                            val.shipping_fee = 0
                            val.hasshipping = 0
                            val.temp_hasshipping = '0'
                            val.total_payable_amount = val.local_pick_total_payable_amount
                        }
                    })
                    if (global.storeConfigration?.transfer_item_location?.value == 1) {
                        var city_equal_check =
                            global.storeConfigration?.transfer_city_location?.value
                        invoice_details.map((val) => {
                            if (
                                val.auction_city != city_equal_check &&
                                val.auction_city ==
                                    global.storeConfigration?.transfer_city_location_from?.value
                            ) {
                                val.transfer_item_enable = 1
                                if (user?.transfer_location == 1) {
                                    val.temp_shipping_fee = val.shipping_fee
                                    val.total_payable_amount =
                                        val.total_payable_amount - val.temp_shipping_fee
                                    val.shipping_fee = 0
                                    val.transfer_fee_location = parseFloat(
                                        global.storeConfigration?.transfer_location_fee?.value,
                                    )
                                    val.total_payable_amount =
                                        parseFloat(val.total_payable_amount) +
                                        parseFloat(val.transfer_fee_location)
                                    val.local_pick_total_payable_amount =
                                        parseFloat(val.local_pick_total_payable_amount) +
                                        parseFloat(val.transfer_fee_location)
                                    val.temp_hasshipping = '0'
                                }
                            } else {
                                val.transfer_fee_location = 0
                                if (user?.transfer_location == 1) {
                                    val.temp_shipping_fee = val.shipping_fee
                                    val.total_payable_amount =
                                        val.total_payable_amount - val.temp_shipping_fee
                                    val.shipping_fee = 0
                                    val.temp_hasshipping = '0'
                                }
                            }
                        })
                        setViewCartItems(invoice_details)
                    } else {
                        setViewCartItems(invoice_details)
                    }
                } else {
                    if (invoice_details.message == 'No invoice found') {
                        setAlert(
                            'You paid this item already / seller removed this item from your dashboard. Kindly refresh the page and check again.',
                            'error',
                        )
                        clearResponse_invoice_details()
                        return handleRedirectInternal(history, `dashboard/mybids?s=1`)
                    }
                }
            }
        }
    }, [invoice_details])

    const viewProductRef = useRef(viewCartItems)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewCartItems
        userRef.current = user
    })
    const handler = (message, type) => {
        messageHandleBuynow(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewCartItems,
            type,
            setRemoveid,
        )
    }
    // useEffect(() => {
    //     if (viewCartItems.length) {
    //         viewCartItems.splice(0, 1)
    //         setViewCartItems([...viewCartItems])
    //     }
    // }, [viewCartItems])
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            //console.log('socket innnnnb')
            handler(data, 'realclosedupdatesbuynow')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'checkbuynowavailable')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdatesbuynow')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'realclosedupdatesbuynow')
            })
        }
    }, [])

    useEffect(() => {
        if (viewCartItems.length) {
            if (global.pluginConfiguration?.shipping?.enable && user && type == 'buynow') {
                // Promise.all(

                viewCartItems.map(async (val, index) => {
                    if (
                        (parseInt(val.shipping_fee) == 0 || val.shipping_fee == '') &&
                        parseInt(val.hasshipping) &&
                        ((val.weight != 0 &&
                            val.height != 0 &&
                            val.length != 0 &&
                            val.width != 0) ||
                            (val.custom_field_5 != '' &&
                                val.custom_field_6 != '' &&
                                val.custom_field_7 != '' &&
                                val.custom_field_8 != ''))
                    ) {
                        var seller_zipcode = ''
                        if (val.custom_field_8.includes('{')) {
                            seller_zipcode = JSON.parse(val.custom_field_8)?.zipcode
                                ? JSON.parse(val.custom_field_8).zipcode
                                : ''
                        }
                        var calculation_data = {
                            weight: val.weight,
                            height: val.height,
                            length: val.length,
                            width: val.width,
                            toPostalCode: user?.zip,
                            fromPostalCode: val.seller_zipcode
                                ? val.seller_zipcode
                                : seller_zipcode,
                            site_id: global.storeDetails?.site_id
                                ? global.storeDetails.site_id
                                : '',
                            project_id: val.project_id || 0,
                            toCountry: user?.country,
                        }
                        setCalculatorLoading(true)
                        viewCartItems[index].api_called = 1
                        const [res] = await Promise.all([
                            apiCall(
                                'post',
                                'calculator',
                                calculation_data,
                                {},
                                'plugin/shipstation/service/getRate',
                                null,
                                true,
                            ),
                        ])

                        if (res.data?.data) {
                            var shipping_rate_calculation = res.data.data
                            if (shipping_rate_calculation.message) {
                                if (shipping_rate_calculation.message.shipping_status) {
                                    if (shipping_rate_calculation.message.shipping_status == 200) {
                                        viewCartItems[index].shipping_fee =
                                            shipping_rate_calculation.message.rate[0]?.shipmentCost
                                                ? parseFloat(
                                                      shipping_rate_calculation.message.rate[0]
                                                          .shipmentCost,
                                                  )
                                                : 0
                                        viewCartItems[index].total_payable_amount =
                                            parseFloat(viewCartItems[index].total_payable_amount) +
                                            parseFloat(viewCartItems[index].shipping_fee)

                                        if (
                                            global.storeConfigration?.transfer_item_location
                                                ?.value == 1
                                        ) {
                                            var city_equal_check =
                                                global.storeConfigration?.transfer_city_location
                                                    ?.value
                                            if (
                                                viewCartItems[index].auction_city !=
                                                    city_equal_check &&
                                                viewCartItems[index].auction_city
                                                    ?.toLowerCase()
                                                    ?.trim() ==
                                                    global.storeConfigration?.transfer_city_location_from?.value
                                                        ?.toLowerCase()
                                                        ?.trim() &&
                                                viewCartItems[index].localpickup == 1
                                            ) {
                                                viewCartItems[index].transfer_item_enable = 1
                                                if (
                                                    user?.transfer_location == 1 &&
                                                    viewCartItems[index].api_called == 1
                                                ) {
                                                    console.log('++++++++++++++', index)
                                                    viewCartItems[index].temp_shipping_fee =
                                                        viewCartItems[index].shipping_fee
                                                    viewCartItems[index].total_payable_amount =
                                                        viewCartItems[index].total_payable_amount -
                                                        viewCartItems[index].temp_shipping_fee
                                                    viewCartItems[index].shipping_fee = 0
                                                    viewCartItems[index].transfer_fee_location =
                                                        parseFloat(
                                                            global.storeConfigration
                                                                ?.transfer_location_fee?.value,
                                                        )
                                                    viewCartItems[index].total_payable_amount =
                                                        parseFloat(
                                                            viewCartItems[index]
                                                                .total_payable_amount,
                                                        ) +
                                                        parseFloat(
                                                            viewCartItems[index]
                                                                .transfer_fee_location,
                                                        )
                                                    viewCartItems[
                                                        index
                                                    ].local_pick_total_payable_amount =
                                                        parseFloat(
                                                            viewCartItems[index]
                                                                .local_pick_total_payable_amount,
                                                        ) +
                                                        parseFloat(
                                                            viewCartItems[index]
                                                                .transfer_fee_location,
                                                        )
                                                    viewCartItems[index].temp_hasshipping = '0'
                                                }
                                            }
                                            // else {
                                            //     viewCartItems[index].transfer_fee_location = 0
                                            //     if (
                                            //         user?.transfer_location == 1 &&
                                            //         viewCartItems[index].api_called == 1
                                            //     ) {
                                            //         console.log('----++++++++++++++')
                                            //         viewCartItems[index].temp_shipping_fee =
                                            //             viewCartItems[index].shipping_fee
                                            //         viewCartItems[index].total_payable_amount =
                                            //             viewCartItems[index].total_payable_amount -
                                            //             viewCartItems[index].temp_shipping_fee
                                            //         viewCartItems[index].shipping_fee = 0
                                            //         viewCartItems[index].temp_hasshipping = '0'
                                            //     }
                                            // }
                                        }
                                        var view_cartitems = viewCartItems
                                        view_cartitems.map((val) => {
                                            if (val.hasshipping == 1 && val.localpickup == 1) {
                                                val.temp_shipping_fee = val.shipping_fee
                                                val.shipping_fee = 0
                                                val.hasshipping = 0
                                                val.temp_hasshipping = '0'
                                                val.total_payable_amount =
                                                    val.local_pick_total_payable_amount
                                            }
                                        })
                                        setViewCartItems((pre) => [...view_cartitems])
                                        // setCalculateTrigger(true)
                                    } else if (
                                        shipping_rate_calculation.message.shipping_status == 500
                                    ) {
                                        // setTotalerror(
                                        //     'Product dimensional or zipCode incorrect.Please check your zipCode or contact seller.',
                                        // )
                                        setAlert(
                                            'Product dimensional or Zip Code incorrect.Please check your Zip Code or contact seller.',
                                            'error',
                                        )
                                    } else {
                                        //setTotalerror('Please Try Again Later!')
                                        setAlert('Please Try Again Later!', 'error')
                                    }
                                } else {
                                    //setTotalerror('Please Try Again Later!')
                                    setAlert('Please Try Again Later!', 'error')
                                }
                            }
                            setCalculatorLoading(false)
                            // setTimeout(() => {
                            //     setCalculatorLoading(false)
                            // }, 6000)
                            // setCalculateTrigger(false)
                        }
                    } else {
                        if (parseInt(val.hasshipping)) {
                            if (
                                viewCartItems[index].total_payable_amount_calculated == undefined &&
                                viewCartItems[index].api_called != 1
                            ) {
                                viewCartItems[index].total_payable_amount =
                                    parseFloat(viewCartItems[index].total_payable_amount) +
                                    parseFloat(viewCartItems[index].shipping_fee)
                                viewCartItems[index].total_payable_amount_calculated = 1
                                setViewCartItems((pre) => [...viewCartItems])
                                setCalculateTrigger(true)
                            }
                        }
                    }
                })
                // ).then((r) => {
                //     // setTimeout(() => {
                //     //     setCalculatorLoading(false)
                //     // }, 3000)
                //     console.log(r, 'trigger')
                // })
            } else {
                if (!global.pluginConfiguration?.shipping?.enable) {
                    viewCartItems.map((val) => {
                        if (val.hasshipping == 1 && val.localpickup == 1) {
                            val.temp_shipping_fee = val.shipping_fee
                            val.shipping_fee = 0
                            val.hasshipping = 0
                            val.temp_hasshipping = '0'
                            val.total_payable_amount = val.local_pick_total_payable_amount
                        }
                    })
                }
                setViewCartItems(viewCartItems)
            }
        }
    }, [viewCartItems_buynow, user])

    return (
        <Container className="dashboard-wrapper">
            <UserActivity page="checkout" />
            {viewCartItems.length !== 0 ? (
                <div className="row checkout-row">
                    <div className="col-md-8">
                        <h2 className="mp-head mb-3">{t('checkout')}</h2>
                        <CheckoutCard
                            items={viewCartItems}
                            currency={props.currency}
                            setItems={setViewCartItems}
                            type={type}
                            calculatorLoading={calculatorLoading}
                        />
                    </div>
                    <div className="col-md-4">
                        {global.storeConfigration?.skyflow_integration?.value == 1 ? (
                            <>
                                <MakePaymentSkyflow
                                    currency={props.currency}
                                    cartCount={viewCartItems.length}
                                    amount={
                                        type === 'buynow'
                                            ? _.sumBy(viewCartItems, (x) => parseFloat(x.bprice))
                                            : _.sumBy(viewCartItems, (x) => parseFloat(x.amount))
                                    }
                                    cart_ids={
                                        type === 'buynow' &&
                                        viewCartItems.map((value) => value.cart_id)
                                    }
                                    type={type}
                                    invoice_id={id}
                                    buyers_premium={_.sumBy(viewCartItems, (x) =>
                                        parseFloat(x.buyers_premium + x.charge_fee),
                                    )}
                                    transaction_fee={_.sumBy(viewCartItems, (x) =>
                                        parseFloat(x.transaction_fee),
                                    )}
                                    shipping_fee={_.sumBy(viewCartItems, (x) =>
                                        parseFloat(x.shipping_fee),
                                    )}
                                    sales_tax={_.sumBy(viewCartItems, (x) =>
                                        parseFloat(x.sales_tax),
                                    )}
                                    local_pick_sales_tax={_.sumBy(viewCartItems, (x) =>
                                        parseFloat(x.local_pick_sales_tax),
                                    )}
                                    total_payable_amount={_.sumBy(viewCartItems, (x) =>
                                        parseFloat(x.total_payable_amount),
                                    )}
                                    local_pick_total_payable_amount={_.sumBy(viewCartItems, (x) =>
                                        parseFloat(x.local_pick_total_payable_amount),
                                    )}
                                    discount_amount={_.sumBy(viewCartItems, (x) =>
                                        parseFloat(x.discount_amount),
                                    )}
                                    redirectUrl={props.redirectUrl}
                                    items={viewCartItems}
                                    setItems={setViewCartItems}
                                    deposit_amount={_.sumBy(viewCartItems, (x) =>
                                        parseFloat(x.deposit_amount),
                                    )}
                                />
                            </>
                        ) : (
                            <MakePayment
                                currency={props.currency}
                                cartCount={viewCartItems.length}
                                storeTheme={props.storeTheme}
                                amount={
                                    type === 'buynow'
                                        ? _.sumBy(viewCartItems, (x) => parseFloat(x.bprice))
                                        : _.sumBy(viewCartItems, (x) => parseFloat(x.amount))
                                }
                                cart_ids={
                                    type === 'buynow' && viewCartItems.map((value) => value.cart_id)
                                }
                                type={type}
                                invoice_id={id}
                                buyers_premium={_.sumBy(viewCartItems, (x) =>
                                    parseFloat(x.buyers_premium + x.charge_fee),
                                )}
                                transaction_fee={_.sumBy(viewCartItems, (x) =>
                                    parseFloat(x.transaction_fee),
                                )}
                                shipping_fee={_.sumBy(viewCartItems, (x) =>
                                    parseFloat(x.shipping_fee),
                                )}
                                sales_tax={_.sumBy(viewCartItems, (x) => parseFloat(x.sales_tax))}
                                local_pick_sales_tax={_.sumBy(viewCartItems, (x) =>
                                    parseFloat(x.local_pick_sales_tax),
                                )}
                                total_payable_amount={_.sumBy(viewCartItems, (x) =>
                                    parseFloat(x.total_payable_amount),
                                )}
                                local_pick_total_payable_amount={_.sumBy(viewCartItems, (x) =>
                                    parseFloat(x.local_pick_total_payable_amount),
                                )}
                                discount_amount={_.sumBy(viewCartItems, (x) =>
                                    parseFloat(x.discount_amount),
                                )}
                                transfer_amount={_.sumBy(viewCartItems, (x) =>
                                    parseFloat(x.transfer_fee_location),
                                )}
                                line_item_fee={_.sumBy(viewCartItems, (x) =>
                                    parseFloat(x.line_item_fee),
                                )}
                                redirectUrl={props.redirectUrl}
                                items={viewCartItems}
                                setItems={setViewCartItems}
                                deposit_amount={_.sumBy(viewCartItems, (x) =>
                                    parseFloat(x.deposit_amount),
                                )}
                                project_id_buynow={viewCartItems.map((data) => {
                                    return data.project_id ? data.project_id : 0
                                })}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <Nodata />
            )}
        </Container>
    )
}

export default CheckoutComponent
