import React, { useState, useContext, useEffect } from 'react'
import PaginationComponent from '../../../Component/Pagination'
import { Link, useHistory } from 'react-router-dom'
import Bidhistory from '../../../Component/Bidhistory'
import { getFilePath } from '../../../utils/commonFunctions'
import './product.css'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Timer from '../../../Product/common/timer'
import Bidding from '../../../Product/components/molecules/Bidding/BiddingItem'
import AuthContext from '../../../Product/context/auth/authContext'
import AddToCart from '../../../utils/CommonFunctionality/Cart/AddToCart'
import TableView from '../../../Component/ProductCard/TableView'
import { socket } from '../../../Product/common/socket'
import moment from 'moment'
import SearchProductList from '../../../Component/ProductCard/product'
import { Button } from '@material-ui/core'
import { getAdsImage } from '../../../Product/common/components'
const SubLotsComponent = (props) => {
    const authContext = useContext(AuthContext)
    const [selectedHistory, setSelectedHistory] = useState({})
    const { user, isAuthenticated } = authContext
    const [auctionTimer, setAuctionTimer] = useState('')
    const history = useHistory()
    const params = new URLSearchParams(window.location.search.substring(1))
    const [auctionView, setAuctionView] = useState(params.get('view') ? params.get('view') : 'List')
    const openHistory = (property) => {
        setSelectedHistory(property)
    }
    const handleAuctionView = (type) => {
        let currentUrlParams = new URLSearchParams(window.location.search)
        currentUrlParams.set('view', type)
        history.push(history.location.pathname + '?' + currentUrlParams.toString())
    }
    // useEffect(() => {
    //     if (socket) {
    //         socket.on('sliservertime', (data) => {
    //             if (moment(data.dTime).isValid()) {
    //                 setAuctionTimer(data.dTime)
    //             }
    //         })
    //     }
    // }, [socket])

    useEffect(() => {
        setAuctionView(params.get('view') ? params.get('view') : 'List')
    }, [window.location.search])

    const [aucAds, setAucAds] = useState({
        deskAucRight: '',
        deskAucLeft: '',
        mobAucTop: '',
        mobAucBottom: '',
    })
    useEffect(() => {
        setAucAds({
            deskAucRight: getAdsImage(1, 2, 4),
            deskAucLeft: getAdsImage(1, 2, 3),
            mobAucTop: getAdsImage(2, 2, 1),
            mobAucBottom: getAdsImage(2, 2, 5),
        })
    }, [])

    return (
        <div className="auctionsAdsCnt">
            {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                <div className="siteAds adsAuc">
                    {aucAds?.deskAucLeft}
                    {aucAds?.mobAucTop}
                </div>
            ) : null}
            <div
                className="table-responsive lineItemBidTable mt-5 sublots-table mySubmitTable"
                id="sublot"
            >
                {props.showviews ? (
                    <div className="d-flex justify-content-end align-items-center flex-wrap mt-5 mb-3">
                        <div className="auctionsMiscFilter">
                            <div className="gridListToggle">
                                <Button
                                    className={auctionView === 'Grid' ? 'active' : ''}
                                    onClick={() => handleAuctionView('Grid')}
                                >
                                    <span className="material-icons">apps</span>Grid
                                </Button>
                                <Button
                                    className={auctionView === 'List' ? 'active' : ''}
                                    onClick={() => handleAuctionView('List')}
                                >
                                    <span className="material-icons">view_list</span>List
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {auctionView == 'List' ? (
                    <TableView
                        lotData={props.lotData}
                        selectedPostion={props.selectedPostion}
                        auctionTimer={auctionTimer}
                        setSubLotData={props.setSubLotData}
                        audio={props.audio}
                        upcomming={props.upcomming}
                    />
                ) : (
                    <SearchProductList
                        listview={false}
                        searchResult={props.lotData}
                        className="liquidationBuyCard"
                        cardType={'grid'}
                        setViewProduct={props.setSubLotData}
                    />
                )}
                <PaginationComponent
                    onPageChange={(pageNumber) => {
                        document.getElementById('sublot').scrollIntoView()
                        props.setSubLotSearch({ ...props.subLotSearch, page: pageNumber })
                    }}
                    totalProductCount={props.subLotDataCount}
                    currentPage={props.subLotSearch.page}
                    resultToShow={props.subLotSearch.limit}
                />
                <Bidhistory
                    open={Object.keys(selectedHistory).length !== 0}
                    value={selectedHistory}
                    serverTime={props.serverTimeSocket}
                    handleClose={() => openHistory({})}
                />
            </div>
            {global?.pluginConfiguration?.advertisement?.enable == 1 ? (
                <div className="siteAds adsAuc">
                    {aucAds?.deskAucRight}
                    {aucAds?.mobAucBottom}
                </div>
            ) : null}
        </div>
    )
}

export default SubLotsComponent
