import React, { useState, useContext, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import ProductContext from '../context/product/productContext'
import { mapData } from './components'

function MakeAnoffer(props) {
    const { insertProductView, insert_make_proposels } = useContext(ProductContext)
    const validationmake_formik = Yup.object({
        proposed_amount: Yup.number()
            .positive('Enter Valid amount')
            .integer('Enter Valid amount')
            .typeError('Enter Valid amount')
            .min(1, `Enter Valid amount`)
            .test('is-decimal', 'Cannot be a decimal', (value) =>
                value ? typeof value === 'number' && value % 1 === 0 : true,
            )
            .required('Enter Valid amount'),
    })

    const make_formik = useFormik({
        initialValues: {
            proposed_amount: '',
            project_id: '',
            user_id: '',
            seller_id: '',
            status: '',
        },
        validationSchema: validationmake_formik,
        onSubmit: async (values) => {
            make_formik.values.project_id = props.item.id
            make_formik.values.user_id = props.user.id
            make_formik.values.seller_id = props.item.user_id
            make_formik.values.status = 'Pending'
            insert_make_proposels(values)
            make_formik.values.proposed_amount = ''
        },
    })

    var make_an_offer = [
        {
            label: 'Make Offer',
            placeholder: 'Enter Price',
            type: 'number',
            class: ' makeMyOffer',
            name: 'proposed_amount',
            formik: make_formik,
        },
    ]

    return (
        <>
            <div className="mineOffer">
                {mapData(make_an_offer)}

                <div className="subOffer">
                    <PrimaryButton
                        onClick={(e) =>
                            props.item?.market_status == 'open' ? make_formik.handleSubmit() : ''
                        }
                        disabled={props.item?.market_status == 'sold'}
                    >
                        {props.item?.market_status == 'sold' ? 'Lot Sold' : `Submit Offer`}
                    </PrimaryButton>
                </div>
            </div>
        </>
    )
}

export default MakeAnoffer
